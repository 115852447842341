<template>
  <div class="home fixed-sn">
    <owgheader></owgheader>
    <div class="flexible-content" >
      <topnavbar />
      <main>
        <div>
          <mdb-container fluid>
            <h1>{{$t('flightscalendar.flightscalendar')}} [HOLASUN]</h1>
            <mdb-row>
              <mdb-col md="12">
                <mdb-alert color="danger" v-if="error">{{error}}</mdb-alert>
                <FullCalendar :options="calendarOptions" />
              </mdb-col>
            </mdb-row>
          </mdb-container>
        </div>
      </main>
    </div>
    <mdb-modal :show="flightinfos!==null || isabstractloading" @close="flightinfos = isabstractloading = alldetailswarning = null" class="text-center" info>
      <mdb-modal-header center :close="false">
        <p class="heading">{{$t('flightscalendar.flightinfos')}}</p>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col>
            <mdb-spinner v-if="isabstractloading" />
            <mdb-alert v-if="alldetailswarning" color="info">bientôt disponible</mdb-alert>
            <mdb-tbl sm class="text-left" v-if="flightinfos">
              <mdb-tbl-body>
                <tr>
                  <td>{{$t('flightscalendar.departuredate')}}</td>
                  <td>{{formatDate(flightinfos.departuredatetime)}}</td>
                </tr>
                <tr>
                  <td>{{$t('flightscalendar.origin')}}</td>
                  <td>{{flightinfos.origin}}</td>
                </tr>
                <tr>
                  <td>{{$t('flightscalendar.arrivaldate')}}</td>
                  <td>{{formatDate(flightinfos.arrivaldatetime)}}</td>
                </tr>
                <tr>
                  <td>{{$t('flightscalendar.destination')}}</td>
                  <td>{{flightinfos.destination}}</td>
                </tr>
                <tr>
                  <td>Numéro de vol</td>
                  <td>{{flightinfos.flightnum}}</td>
                </tr>
                <tr>
                  <td>{{$t('flightscalendar.aircraft')}}</td>
                  <td>{{flightinfos.aircraft}}</td>
                </tr>
                <tr>
                  <td>{{$t('flightscalendar.paxesseats')}}</td>
                  <td>{{flightinfos.nbpaxes}} / {{flightinfos.nbseats}}</td>
                </tr>
                <tr>
                  <td></td>
                  <td class="text-right"><mdb-btn size="sm" color="elegant" v-on:click="gotoflightdetails(flightinfos.id)">{{$t('holasun.passenger_of_flight')}}</mdb-btn></td>
                </tr>
              </mdb-tbl-body>
            </mdb-tbl>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer right>
        <mdb-btn outline="elegant" @click="flightinfos = isabstractloading = alldetailswarning = null">{{$t('flightscalendar.close')}}</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import {
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbAlert,
  mdbModal,
  mdbModalHeader,
  mdbModalBody,
  mdbModalFooter,
  mdbBtn,
  mdbTbl,
  mdbTblBody,
  mdbSpinner,
} from 'mdbvue';
import owgheader from '@/components/Header.vue';
import topnavbar from '@/components/Topnavbar.vue';
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import allLocales from '@fullcalendar/core/locales-all';
import Apicall from '@/libs/Apicall';

// on utilise ça : https://fullcalendar.io/docs/vue

export default {
  name: 'holasunflightslist',
  data() {
    return {
      flights: [],
      isloading: true,
      currentstartdate: new Date(),
      calendarOptions: {
        timeZone: 'local',
        locales: allLocales,
        locale: this.$store.state.currentlang,
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        events: [],
        eventClick: this.handleEventClick,
        datesSet: this.handleChangeMonth,
      },
      error: null,
      flightinfos: null,
      isabstractloading: false,
      alldetailswarning: false,
    };
  },
  components: {
    owgheader,
    topnavbar,
    mdbContainer,
    FullCalendar,
    mdbRow,
    mdbCol,
    mdbAlert,
    mdbModal,
    mdbModalHeader,
    mdbModalBody,
    mdbModalFooter,
    mdbBtn,
    mdbTbl,
    mdbTblBody,
    mdbSpinner,
  },
  methods: {
    loadFlightsForDateRange(from, to) {
      this.error = null;
      const self = this;
      const api = new Apicall();
      const neweventslist = [];
      api.call('POST', 'api/holasun/flightlist', { fromdate: from, todate: to }).then((response) => {
        response.data.flights.forEach((item, index) => {
          neweventslist.push({
            id: item.id,
            title: `${item.origin} ➡ ${item.destination} #${item.flightnum}`,
            start: item.departuredatetime,
            end: item.arrivaldatetime,
            backgroundColor: item.eventcolor,
          });
        });

        self.calendarOptions.events = neweventslist;
      });
    },
    handleEventClick(args) {
      this.error = null;
      this.isabstractloading = true;
      const flightid = args.event.id;
      const self = this;
      const api = new Apicall();
      api.call('POST', 'api/holasun/get-flight-abstract', { id: flightid }).then((response) => {
        if (response.status === 'OK') {
          self.flightinfos = response.data;
        } else {
          self.error = response.msg;
        }
        self.isabstractloading = false;
      });
    },
    handleChangeMonth(args) {
      // s'assurer que ça ne loop pas non stop
      if (this.currentstartdate.getTime() !== args.start.getTime()) {
        this.currentstartdate = args.start;
        this.loadFlightsForDateRange(args.start, args.end);
      }
    },
    formatDate(_thedate) {
      const thedate = new Date(_thedate);
      const options = {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      };
      return thedate.toLocaleDateString(this.$store.state.currentlang === 'fr' ? 'fr-CA' : 'en-CA', options);
    },
    gotoflightdetails(flightid) {
      this.$router.push({ name: 'HolasunFlightDetails', params: { id: flightid } });
    },
  },
};
</script>
